<template>
<div class="dashboard-page">
  <!-- filter: period  -->
  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget class="filter-widget">
        <template slot="header">
          <span class="title">Analytics</span>
        </template>
        <div class="row">
          <div class="col-md-6">
            <label class="field-label">Date</label>
            <date-range-picker
              ref="picker"
              :opens="filter.date.opens"
              :locale-data="{ firstDay: 1, format: 'MMMM D, YYYY' }"
              :min-date="filter.date.minDate" :maxDate="filter.date.maxDate"
              :single-date-picker="filter.date.singleDatePicker"
              :time-picker="filter.date.timePicker"
              :time-picker24-hour="filter.date.timePicker24Hour"
              :show-week-numbers="filter.date.showWeekNumbers"
              :show-dropdowns="filter.date.showDropdowns"
              :auto-apply="filter.date.autoApply"
              :always-show-calendars="filter.date.alwaysShowCalendars"
              :linked-calendars="filter.date.linkedCalendars"
              :ranges="filter.date.ranges"
              v-model="filter.date.dateRange"
              @update="filterDateUpdateValues"
            >
            </date-range-picker>
          </div>
          <div class="col-md-6">
            <label class="field-label">Business</label>
            <multiselect
              v-model="filter.business.value"
              :options="filter.business.options"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder=""
              label="label"
              track-by="label"
              :preselect-first="true"
              :show-labels="false"
              :allowEmpty="false"
              @input="filterDateUpdateValues"
            ></multiselect>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <b-tabs v-model="activeTabIndex" card>
        <b-tab title="Calls" active>
          <CallTab ref="callRef" v-if="activeTabIndex === 0" :filter="filter"/>
        </b-tab>
        <b-tab title="Messages">
          <MessageTab ref="messageRef" v-if="activeTabIndex === 1" :filter="filter"/>
        </b-tab>
        <b-tab title="KPI">
          <KpiTab ref="kpiRef" v-if="activeTabIndex === 2" :filter="filter"/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</div>

</template>

<script>
import Moment from 'moment';
import CallTab from './Call';
import MessageTab from './Message';
import KpiTab from './Kpi';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
    DateRangePicker,
    CallTab,
    MessageTab,
    KpiTab,
  },
  data: function () {
    return {
      activeTabIndex: 0,
      filter : {
        business : {
          value: {id: 'all', label: 'All Businesses'},
          options: [
            {id: 'all', label: 'All Businesses'},
          ]
        },
        date : {
          opens: 'right',
          minDate: null,
          maxDate: null,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: false,
          showWeekNumbers: false,
          showDropdowns: false,
          autoApply: false,
          dateRange: {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
          },
          linkedCalendars: true,
          ranges: {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(7, 'days'), moment()],
            'Last 30 Days': [moment().subtract(30, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          },
          alwaysShowCalendars: false,
        }
      },
    }
  },
  mounted() {
    this.getBusinesses()
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    formatPhone(val) {
      if (!val)
        return '';
      if (!val.startsWith('+'))
        val = '+' + val;

      const phoneNumber = parsePhoneNumberFromString(val)
      return phoneNumber.formatNational()
    },

    getBusinesses() {
      const param = {
        status: "active",
      };
      this.$store
        .dispatch("agency/fetchAllBusinesses", param)
        .then(({ data }) => {
          if (data && data.length)
          {
            this.filter.business.options = [
              { id: 'all', label: 'All Businesses'},
              ...data.map(item => {
                return {
                  id: item.id,
                  label: item.name + ` (#${item.id})`
                }
              })
            ]
          }
        })
        .catch(() => {});
    },

    filterDateUpdateValues(range) {
      if (this.activeTabIndex === 0) {
        this.$refs.callRef.handleFilterUpdate()
      } else if (this.activeTabIndex === 1) {
        this.$refs.messageRef.handleFilterUpdate()
      } else if (this.activeTabIndex === 2) {
        this.$refs.kpiRef.handleFilterUpdate()
      }
      return true
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter-widget {
    margin-bottom: 0;
  }
</style>

<style lang="scss">
  .vue-daterange-picker {
    display: block !important;

    //make range picker behave as adequate input
    .reportrange-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0.45rem 0.75rem;
      border: .0625rem solid #e7e7e7;
      height: inherit;
    }

    .daterangepicker {
      transform: scale(1);
      opacity: 1;

      //Move ranges list to the right
      .ranges {
        order: 10;
      }
    }
  }
</style>

